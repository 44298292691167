import Head from 'next/head';
import Image from 'next/image';
import styles from 'styles/Home.module.scss';
import Login from 'components/Login';

export default function Home() {
  return (
    <div className={styles.container}>
      <main className={styles.main}></main>
    </div>
  );
}
