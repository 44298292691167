import Image from 'next/image';
import { useState, useEffect, useContext } from 'react';
import styles from 'styles/Login.module.scss';
import dynamic from 'next/dynamic';

import { AdminContext } from 'Context';
import getLoginCode from 'services/getlogincode';
import setLogin from 'services/setlogin';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import getUser from 'services/getuser';

const ReactCodeInput = dynamic(import('react-code-input'));

export default function Login({ shop, loginCode }) {
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState(loginCode || '');
  const [validatingCode, setValidatingCode] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const {
    setUser, user, incorrectUser, country, slugExists,
  } = useContext(AdminContext);

  const propsCode = {
    className: ReactCodeInput,
    inputStyle: {
      fontFamily: 'monospace',
      margin: '5px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '22px',
      height: '40px',
      textAlign: 'center',
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.0706238), rgba(255, 255, 255, 0.0706238))',
      borderRadius: '9px',
      color: 'white',
      fontWeight: '600',
      border: '1px solid lightskyblue',
    },
  };

  const titleText = country === 'ar' ? 'Validá tu número de WhatsApp' : 'Valida tu número de WhatsApp';
  const titleValidationCode = country === 'ar' ? 'Revisá tu WhatsApp' : 'Revisa tu WhatsApp';
  const textEnterCode = country === 'ar' ? 'Ingresá tu código aquí:' : 'Ingresa tu código aquí:';

  useEffect(() => {
    if (code.length === 6) {
      if (loginCode === null) {
        setValidatingCode(true);
        setErrorCode(false);
      } else {
        setCodeSent(true);
        setValidatingCode(true);
      }
      setLogin(code)
        .then((res) => {
          if (!res) {
            if (loginCode === null) {
              setCode('');
              setValidatingCode(false);
              setErrorCode(true);
            } else {
              setValidatingCode(false);
              setCodeSent(false);
            }
          } else {
            getUser()
              .then((userRes) => {
                if (!userRes.error) {
                  setUser(userRes.customer_id);
                } else {
                  setValidatingCode(false);
                  setErrorCode(true);
                }
              });
          }
        });
    }
  }, [code]);

  const handleChangeCode = (value) => {
    setCode(value.toString());
  };

  const handleCode = () => {
    getLoginCode(shop);
    setCodeSent(true);
    setErrorCode(false);
  };

  return (
    <div className={styles.login}>
      <div className={styles.logo}>
        <Image
          src="/imgs/logo-login.png"
          layout="responsive"
          width={154}
          height={110}
          alt="logo"
        />
      </div>
      {
          ((user !== null && !user && slugExists !== null) || incorrectUser)
          && (
            slugExists
              ? (
                <>
                  {
              !codeSent
                ? (
                  <>
                    <h1 style={{
                      color: 'white', marginBottom: '0px', fontWeight: '500', fontSize: '26px',
                    }}
                    >
                      {titleText}
                    </h1>
                    <p style={{
                      fontWeight: '400', fontStyle: 'italic', fontSize: '15px', color: '#FFFFFF', opacity: '80%', maxWidth: '244px', textAlign: 'center', margin: '0 0 50px 0', lineHeight: '18px', marginTop: '10px',
                    }}
                    >
                      Vamos a enviarte un mensaje con un código y un link para que ingreses a tu Manager de forma segura.
                    </p>
                  </>
                )
                : !validatingCode
              && (
              <>
                <h1 style={{
                  color: 'white', marginBottom: '0px', fontWeight: '700', fontSize: '26px',
                }}
                >
                  {titleValidationCode}
                </h1>
                <p style={{
                  fontWeight: '400', fontSize: '15px', color: '#FFFFFF', opacity: '80%', maxWidth: '244px', textAlign: 'center', margin: '0 0 50px 0', lineHeight: '18px', marginTop: '10px',
                }}
                >
                  Te enviamos un mensaje con un código y un link.
                </p>
              </>
              )
            }
                  {
              !codeSent
                ? (
                  <button
                    style={{
                      backgroundColor: '#FF6625', width: '292px', height: '46px', padding: '15px 26px', borderRadius: '100px',
                    }}
                    onClick={handleCode}
                  >
                    OBTENER CÓDIGO
                  </button>
                )
                : !validatingCode
                  ? (
                    <>
                      <p style={{
                        fontWeight: '400', fontSize: '15px', color: '#FFFFFF', opacity: '80%', maxWidth: '244px', textAlign: 'center', marginBottom: '18px', lineHeight: '18px',
                      }}
                      >
                        {textEnterCode}
                      </p>
                      <ReactCodeInput type="number" inputMode="numeric" fields={6} onChange={handleChangeCode} {...propsCode} />
                      {
                  !errorCode
                  && (
                  <p style={{
                    fontWeight: '400', fontSize: '15px', color: '#FFFFFF', textAlign: 'center', marginTop: '18px', lineHeight: '18px',
                  }}
                  >
                    ¿No recibiste el código?
                    {' '}
                    <b onClick={handleCode}>REENVIAR CÓDIGO</b>
                  </p>
                  )
                }
                    </>
                  ) : (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress color="success" />
                    </Box>
                  )
}
                  {
              errorCode
              && (
              <p style={{
                fontWeight: '400', fontSize: '15px', color: '#FFFFFF', textAlign: 'center', marginTop: '18px', lineHeight: '18px', padding: '0 5%',
              }}
              >
                * el código ingresado es incorrecto o ya expiró. Ingresalo nuevamente u obtiene uno nuevo.
                {' '}
                <b onClick={handleCode}>REENVIAR CÓDIGO</b>
              </p>
              )
            }
                </>
              )
              : (
                <p style={{
                  fontWeight: '400', fontSize: '15px', color: '#FFFFFF', textAlign: 'center', marginTop: '18px', lineHeight: '18px', padding: '0 5%',
                }}
                >
                  Página no encontrada. Verifica la dirección ingresada.
                </p>
              )
          )
        }
      {/* <div
          style={{
            width: '80%',
            minHeight: '100px',
            backgroundColor: 'white',
            borderRadius: '6px',
          }}
        >
          <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
            <label>
              Contraseña:
              <input
                className='input is-normal'
                type='password'
                name='pass'
                placeholder='Escribe tu contraseña'
                onChange={handleChange}
                style={{ border: '1px solid gray', marginTop: '5px' }}
              />
            </label>
            {error && <p>Error de autenticación, verifica tu contraseña.</p>}
            <button>ENTRAR</button>
          </form>
        </div> */}
    </div>
  );
}
